import {
  MaintenanceOptionType,
  MaintenanceType
} from '@/hooks/settings/settings.interface'

type ProjectType = {
  projectId: number
  workspaceId: number
}
type GetMaintenanceOptionsProps = {
  options: MaintenanceType
  project: ProjectType
}

type MaintenanceOption = {
  label: string
  value: string
}

function getProjectMaintenanceOptions(
  options: MaintenanceType,
  project: ProjectType
): MaintenanceOptionType[] {
  const { workspaceId, projectId } = project
  const workspaceKey = String(workspaceId)
  const projectKey = String(projectId)

  // find with exact workspaceId и projectId
  const workspace = options[workspaceKey]
  if (workspace && typeof workspace === 'object' && !Array.isArray(workspace)) {
    const projectTasks = workspace[projectKey]
    if (projectTasks) {
      return projectTasks
    }

    // get workspace default if project options not provided
    if (workspace.default) {
      return workspace.default
    }
  }

  // get basic default
  if (options.default) {
    return options.default
  }

  return []
}

export const getMaintenanceOptions = ({
  options,
  project
}: GetMaintenanceOptionsProps): MaintenanceOption[] => {
  const projectOptions = getProjectMaintenanceOptions(options, project)
  return projectOptions.map((data, idx) => ({
    label: data.type,
    value: idx + ':dl:' + data.recipient
  }))
}
