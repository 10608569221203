const toDate = (str: string) => new Date(str)

// Функция для ограничения слотов по интервалам
export const validateConstraintsSlot = (slots, constraints, bypass = false) => {
  if (bypass) return slots

  return slots.flatMap((slot) => {
    const slotStart = toDate(slot.start)
    const slotEnd = toDate(slot.end)

    return constraints.flatMap((constraint) => {
      const constraintStart = toDate(constraint.from)
      const constraintEnd = toDate(constraint.to)

      // Проверка пересечения интервалов
      if (slotEnd <= constraintStart || slotStart >= constraintEnd) {
        // Если слот полностью вне интервала ограничения
        return []
      }

      // Корректировка слота в рамках интервала
      const adjustedStart =
        slotStart < constraintStart ? constraintStart : slotStart
      const adjustedEnd = slotEnd > constraintEnd ? constraintEnd : slotEnd

      // Проверка на корректность скорректированного слота
      if (adjustedStart >= adjustedEnd) {
        return [] // Если после корректировки интервал стал некорректным
      }

      return [
        {
          start: adjustedStart,
          end: adjustedEnd
        }
      ]
    })
  })
}
