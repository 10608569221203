import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useEffect } from 'react'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'

const MapController = () => {
  const polygon = useMapStore((state) => state.polygon)
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const mapLayer = useMapStore((state) => state.mapLayer)
  const setPolygon = useMapStore((state) => state.setPolygon)
  const updateDepartments = useMapStore((state) => state.updateDepartments)

  const departments = useSettingsSelector(
    (settings) => settings.departments,
    []
  )

  useEffect(() => {
    if (departments) {
      updateDepartments(departments)
    }
  }, [departments])

  const onLayerChange = (event) => {
    const {
      detail: { layerId }
    } = event
    setPolygon(layerId)
  }

  useEffect(() => {
    document.addEventListener('layer-change', onLayerChange, true)

    return () =>
      document.removeEventListener('layer-change', onLayerChange, true)
  }, [])

  useEffect(() => {
    const current = polygon == activeLayer
    if (!current) {
      setPolygon(null)
    }
  }, [activeLayer, polygon])

  return null
}

export default MapController
