import { useEffect, useState } from 'react'
import { useMapStore } from '@/stores/mapStore'
import Close from '@/components/Close'
import Checkbox from '@/components/shared/map/layer-selector/checkbox'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import styled, { css } from 'styled-components'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'

const LayerSelectorModal = ({ close, option, setOption }) => {
  const [selectedOption, setSelectedOption] = useState<string>(option)
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({})
  const layers = useMapStore((state) => state.layers)
  const setLayers = useMapStore((state) => state.setLayers)
  const updateDepartments = useMapStore((state) => state.updateDepartments)
  const departments = useSettingsSelector(
    (settings) => settings.departments,
    []
  )

  useEffect(() => {
    setChecked(layers)
  }, [layers])

  useEffect(() => {
    if (departments) {
      updateDepartments(departments)
    }
  }, [departments, layers])

  const handleCheck = (section: string) => {
    const isVisible = checked[section]

    setChecked((prev) => ({
      ...prev,
      [section]: !isVisible
    }))
  }

  // const handleDepartmentsCheck = () => {
  //   const departmentKeys = Object.keys(checked).filter((chk) =>
  //     chk.includes('departments.')
  //   )
  //   const isDepartmentKeysChecked = Object.entries(checked).find(
  //     ([key, value]) => key.includes('departments.') && value
  //   )
  //
  //   let newChecked = { ...checked }
  //
  //   if (isDepartmentKeysChecked) {
  //     departmentKeys.forEach((k) => {
  //       newChecked[k] = false
  //     })
  //   } else {
  //     departmentKeys.forEach((k) => {
  //       newChecked[k] = true
  //     })
  //   }
  //   setChecked(newChecked)
  // }

  const handleApply = () => {
    setLayers(checked)
    setOption(selectedOption)
    close()
  }

  return (
    <LayerWrapper>
      <Header>
        <Caption>Слои</Caption>
        <Close color="#000" onClick={close} />
      </Header>
      <Content>
        <div>
          <div>
            {/*<pre>{JSON.stringify(checked, null, 4)}</pre>*/}
            <LayerOption
              onClick={setSelectedOption.bind(null, 'basic')}
              selected={selectedOption === 'basic'}
            >
              Базовые
            </LayerOption>
            <List>
              <li>
                <Checkbox
                  checked={checked['layers']}
                  onChange={handleCheck.bind(null, 'layers')}
                  label="Разметка уровней"
                />
              </li>
              <li>
                <Checkbox
                  checked={checked['not-bookable']}
                  onChange={handleCheck.bind(null, 'not-bookable')}
                  label="Небронируемые объекты"
                />
              </li>
              <li>
                <Checkbox
                  checked={checked['bookable']}
                  onChange={() => {
                    const isChecked = checked['bookable']

                    if (isChecked) {
                      setChecked((prev) => ({
                        ...prev,
                        bookable: false
                      }))
                    } else {
                      setChecked((prev) => ({
                        ...prev,
                        bookable: true
                      }))
                    }
                  }}
                  label="Бронируемые объекты"
                />
              </li>
              <li>
                <Checkbox
                  checked={checked['employees']}
                  onChange={handleCheck.bind(null, 'employees')}
                  label="Сотрудники"
                />
              </li>
            </List>
          </div>
          <LayerOption
            onClick={setSelectedOption.bind(null, 'load')}
            selected={selectedOption === 'load'}
          >
            Нагрузка на рабочее место
          </LayerOption>
        </div>
      </Content>
      <Footer>
        <PrimaryButton type="button" onClick={handleApply}>
          Применить
        </PrimaryButton>
      </Footer>
    </LayerWrapper>
  )
}

export default LayerSelectorModal

const LayerOption = styled.div<{ selected: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #2c2c2c;
  position: relative;
  padding-left: 32px;

  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      &::after {
        position: absolute;
        content: ' ';
        width: 10px;
        height: 10px;
        top: 3px;
        left: 6px;
        background: #1e22aa;
        border-radius: 50%;
      }
    `}
  &::before {
    position: absolute;
    content: ' ';
    width: 18px;
    height: 18px;
    top: -3px;
    left: 0;
    border-radius: 50%;
    border: 2px solid #1e22aa;
  }
`

const List = styled.ul`
  margin: 8px 0 16px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #333333;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Content = styled.div`
  margin: 24px 0;
`

const LayerWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  max-width: 633px;
  width: 100%;
  padding: 24px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Caption = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2c2c2c;
`
