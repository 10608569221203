import * as yup from 'yup'

export const bookingSchema = yup.object().shape({
    seat: yup.object().shape({
        id: yup.string().required('Некорретное расположение'),
        name: yup.string().required()
    }).required(),
    user: yup.object().shape({
        id: yup.string().required('Некорретно выбран сотрудник'),
        name: yup.string().required()
    }).required('Выберите сотрудника'),
    type: yup.string().required(),
    dates: yup.array(yup.object().shape({
        start: yup.date().required('Выберите дату начала'),
        end: yup.date().required('Выберите дату окончания')
        // @ts-ignore
        .when('start', (start) => {
            if (start) {
                return yup.date()
                    .min(start, 'Некорректная дата окончания')
                    .typeError('Выберите дату окончания')
            }
        })
    })
    )
    .required()
})

export const bookingConstantSchema = yup.object().shape({
    seat: yup.object().shape({
        id: yup.string().required('Некорретное расположение'),
        name: yup.string().required()
    }).required(),
    user: yup.object().shape({
        id: yup.string().required('Некорретно выбран сотрудник'),
        name: yup.string().required()
    }).required('Выберите сотрудника'),
    type: yup.string().required(),
})

export const bookingRecurentSchema = yup.object().shape({
    seat: yup.object().shape({
        id: yup.string().required('Некорретное расположение'),
        name: yup.string().required()
    }).required(),
    user: yup.object().shape({
        id: yup.string().required('Некорретно выбран сотрудник'),
        name: yup.string().required()
    }).required('Выберите сотрудника'),
    type: yup.string().required(),
})