import { FC } from 'react'
import SvgIcon from './svg-icon/SvgIcon'
import { IconProps } from './svg-icon/svg-icon.interface'

const FocusIcon: FC<IconProps & { color?: string }> = ({ color = '#1e22aa', ...props }) => {
	return (
		<SvgIcon {...props}>
			<svg
				fill={color}
				height="200px"
				width="200px"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 512 512"
			>
				<path d="M213.227,317.895c-0.031-0.315-0.09-0.621-0.135-0.933c-0.054-0.377-0.098-0.756-0.173-1.13 c-0.071-0.358-0.169-0.704-0.258-1.055c-0.081-0.324-0.152-0.649-0.249-0.969c-0.104-0.344-0.233-0.677-0.354-1.013 c-0.115-0.32-0.22-0.642-0.35-0.957c-0.13-0.314-0.283-0.615-0.428-0.921c-0.153-0.325-0.297-0.653-0.468-0.971 c-0.157-0.293-0.336-0.572-0.506-0.857c-0.186-0.312-0.363-0.628-0.566-0.932c-0.211-0.315-0.446-0.612-0.673-0.915 c-0.19-0.254-0.366-0.514-0.569-0.761c-0.443-0.54-0.91-1.059-1.403-1.552c-0.004-0.004-0.006-0.008-0.01-0.011 c-0.004-0.004-0.008-0.007-0.012-0.011c-0.493-0.492-1.012-0.959-1.551-1.402c-0.247-0.203-0.508-0.379-0.762-0.57 c-0.303-0.227-0.6-0.462-0.915-0.673c-0.304-0.203-0.619-0.379-0.931-0.565c-0.286-0.171-0.565-0.35-0.859-0.507 c-0.318-0.17-0.645-0.314-0.97-0.467c-0.306-0.145-0.608-0.298-0.922-0.428c-0.315-0.13-0.637-0.236-0.957-0.35 c-0.337-0.121-0.669-0.25-1.013-0.354c-0.32-0.097-0.645-0.168-0.969-0.249c-0.351-0.089-0.698-0.187-1.056-0.258 c-0.375-0.074-0.753-0.118-1.13-0.172c-0.311-0.044-0.618-0.104-0.933-0.135c-0.701-0.069-1.404-0.106-2.107-0.106H85.333 C73.551,298.667,64,308.218,64,320s9.551,21.333,21.333,21.333h55.163L6.248,475.582c-8.331,8.331-8.331,21.839,0,30.17 c8.331,8.331,21.839,8.331,30.17,0l134.248-134.248v55.163c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333 V320.001C213.333,319.298,213.296,318.595,213.227,317.895z"></path>
				<path d="M371.503,341.333h55.163c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333H320.001 c-0.703,0-1.406,0.037-2.107,0.106c-0.315,0.031-0.621,0.09-0.933,0.135c-0.377,0.054-0.756,0.098-1.13,0.173 c-0.358,0.071-0.704,0.169-1.055,0.258c-0.324,0.081-0.649,0.152-0.969,0.249c-0.344,0.104-0.677,0.233-1.013,0.354 c-0.32,0.115-0.642,0.22-0.957,0.35c-0.314,0.13-0.615,0.283-0.921,0.428c-0.325,0.153-0.653,0.297-0.971,0.468 c-0.293,0.157-0.572,0.336-0.857,0.506c-0.312,0.186-0.628,0.363-0.932,0.566c-0.315,0.211-0.611,0.445-0.913,0.671 c-0.255,0.191-0.516,0.368-0.764,0.571c-0.535,0.439-1.05,0.903-1.54,1.392c-0.008,0.007-0.016,0.014-0.023,0.021 s-0.014,0.016-0.021,0.023c-0.488,0.49-0.952,1.004-1.392,1.54c-0.204,0.248-0.38,0.509-0.571,0.764 c-0.226,0.302-0.461,0.598-0.671,0.913c-0.204,0.304-0.38,0.62-0.566,0.932c-0.17,0.285-0.349,0.564-0.506,0.857 c-0.17,0.318-0.315,0.646-0.468,0.971c-0.145,0.306-0.297,0.607-0.428,0.921c-0.13,0.315-0.236,0.637-0.35,0.957 c-0.121,0.337-0.25,0.669-0.354,1.013c-0.097,0.32-0.168,0.646-0.249,0.969c-0.089,0.351-0.187,0.698-0.258,1.055 c-0.074,0.375-0.118,0.753-0.173,1.13c-0.044,0.311-0.104,0.617-0.135,0.933c-0.069,0.7-0.106,1.403-0.106,2.105v106.667 c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-55.163l134.248,134.248c8.331,8.331,21.839,8.331,30.17,0 c8.331-8.331,8.331-21.839,0-30.17L371.503,341.333z"></path>
				<path d="M298.908,195.039c0.054,0.377,0.098,0.755,0.172,1.13c0.071,0.358,0.169,0.705,0.258,1.056 c0.081,0.323,0.152,0.649,0.249,0.969c0.104,0.344,0.233,0.677,0.354,1.013c0.115,0.32,0.22,0.642,0.35,0.957 c0.13,0.314,0.283,0.616,0.428,0.922c0.153,0.325,0.297,0.652,0.467,0.97c0.157,0.294,0.337,0.573,0.507,0.859 c0.186,0.312,0.362,0.627,0.565,0.931c0.211,0.315,0.446,0.612,0.673,0.915c0.19,0.254,0.367,0.515,0.57,0.762 c0.443,0.539,0.909,1.058,1.402,1.551c0.004,0.004,0.007,0.008,0.011,0.012c0.004,0.004,0.008,0.006,0.011,0.01 c0.494,0.493,1.012,0.96,1.552,1.403c0.247,0.203,0.507,0.379,0.761,0.569c0.303,0.227,0.6,0.462,0.915,0.673 c0.304,0.204,0.62,0.38,0.932,0.566c0.285,0.17,0.564,0.349,0.857,0.506c0.318,0.17,0.646,0.315,0.971,0.468 c0.306,0.145,0.607,0.297,0.921,0.428c0.315,0.13,0.637,0.236,0.957,0.35c0.337,0.121,0.669,0.25,1.013,0.354 c0.32,0.097,0.646,0.168,0.969,0.249c0.351,0.089,0.698,0.187,1.055,0.258c0.375,0.074,0.753,0.118,1.13,0.173 c0.311,0.044,0.617,0.104,0.933,0.135c0.7,0.069,1.403,0.106,2.105,0.106h106.667c11.782,0,21.333-9.551,21.333-21.333 s-9.551-21.333-21.333-21.333h-55.163L505.752,36.418c8.331-8.331,8.331-21.839,0-30.17c-8.331-8.331-21.839-8.331-30.17,0 L341.333,140.497V85.333C341.333,73.551,331.782,64,320,64s-21.333,9.551-21.333,21.333V192c0,0.703,0.037,1.405,0.106,2.105 C298.804,194.421,298.863,194.727,298.908,195.039z"></path>
				<path d="M140.497,170.667H85.333C73.551,170.667,64,180.218,64,192s9.551,21.333,21.333,21.333H192 c0.703,0,1.405-0.037,2.105-0.106c0.315-0.031,0.622-0.09,0.933-0.135c0.377-0.054,0.755-0.098,1.13-0.172 c0.358-0.071,0.705-0.169,1.056-0.258c0.323-0.081,0.649-0.152,0.969-0.249c0.344-0.104,0.677-0.233,1.013-0.354 c0.32-0.115,0.642-0.22,0.957-0.35c0.314-0.13,0.616-0.283,0.922-0.428c0.325-0.153,0.652-0.297,0.97-0.467 c0.294-0.157,0.573-0.337,0.859-0.507c0.312-0.186,0.627-0.362,0.931-0.565c0.316-0.211,0.613-0.447,0.917-0.674 c0.253-0.19,0.513-0.365,0.759-0.568c1.087-0.892,2.085-1.889,2.977-2.977c0.202-0.246,0.378-0.506,0.568-0.759 c0.228-0.304,0.463-0.601,0.674-0.917c0.203-0.304,0.379-0.619,0.565-0.931c0.171-0.286,0.35-0.565,0.507-0.859 c0.17-0.318,0.314-0.645,0.467-0.97c0.145-0.306,0.298-0.608,0.428-0.922c0.13-0.315,0.236-0.637,0.35-0.957 c0.121-0.337,0.25-0.669,0.354-1.013c0.097-0.32,0.168-0.645,0.249-0.969c0.089-0.351,0.187-0.698,0.258-1.056 c0.074-0.375,0.118-0.753,0.172-1.13c0.044-0.311,0.104-0.618,0.135-0.933c0.069-0.701,0.106-1.404,0.106-2.107V85.333 C213.333,73.551,203.782,64,192,64s-21.333,9.551-21.333,21.333v55.163L36.418,6.248c-8.331-8.331-21.839-8.331-30.17,0 c-8.331,8.331-8.331,21.839,0,30.17L140.497,170.667z"></path>
				<path d="M298.667,234.667c0-11.776-9.557-21.333-21.333-21.333h-42.667c-11.776,0-21.333,9.557-21.333,21.333v42.667 c0,11.776,9.557,21.333,21.333,21.333h42.667c11.776,0,21.333-9.557,21.333-21.333V234.667z"></path>{' '}
			</svg>
		</SvgIcon>
	)
}

export default FocusIcon