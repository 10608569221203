import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import React from 'react'
import styled from 'styled-components'
import { Field, useFormikContext } from 'formik'
import RemoveIcon from '@/components/icons/RemoveIcon'
import { DatePickerField } from '@/ui/components/Field/DatePickerField'
import PlusIcon from '@/components/icons/PlusIcon'
import {
  addMinutes,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfToday
} from 'date-fns'
import useResponsive from '@/hooks/useResponsive'
import { translate } from '@/i18n'
import { useIntervalsFilters } from './use-intervals-filters'
import { isEqual } from 'lodash'
import { BtnWrapper } from '@/components/shared/booking/form/intervals/NoBookingIntervals'
import {
  isSlotValidPredicate,
  validateIntervalSlots
} from '@/components/shared/booking/form/intervals/validate-slots'
import { validateConstraintsSlot } from '@/components/shared/booking/form/intervals/validate-constraints'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'

const BookingInterval = ({ name, interval, index, count, helpers }) => {
  const { setFieldValue, values } = useFormikContext<any>()
  const { isDesktop } = useResponsive()
  const adminRestricted = useSettingsSelector(
    (settings) => settings.adminBookingRestricted,
    true
  )
  const start = interval.start ? addMinutes(interval.start, 30) : null
  const {
    filterStartTime,
    filterEndTime,
    filterStartDate,
    filterEndDate,
    timeForBooking,
    isRestrictedLayer
  } = useIntervalsFilters(start)

  const openStartDate = isRestrictedLayer
    ? timeForBooking.at(0)?.from || startOfToday()
    : startOfToday()

  const handleStartChange = (value: Date) => {
    // Найдем интервал, в который попадает value, или ближайший к нему интервал
    const matchingInterval =
      timeForBooking.find((interval) =>
        isWithinInterval(value, { start: interval.from, end: interval.to })
      ) ||
      timeForBooking.reduce((earliest, current) =>
        isBefore(current.from, earliest.from) ? current : earliest
      )

    // Установим value на начало найденного интервала, если он не попадает ни в один из интервалов
    if (
      !matchingInterval ||
      !isWithinInterval(value, {
        start: matchingInterval.from,
        end: matchingInterval.to
      })
    ) {
      value = matchingInterval.from
    }

    // Определяем стандартное время окончания (по умолчанию +30 минут)
    const defaultEnd = addMinutes(value, 30)

    setFieldValue(`${name}[${index}].start`, value)

    // Если конечное время не входит в тот же интервал, что и начало, корректируем его
    if (
      !isWithinInterval(interval?.end, {
        start: matchingInterval.from,
        end: matchingInterval.to
      }) ||
      isBefore(interval.end, value) ||
      isEqual(interval.end, value)
    ) {
      setFieldValue(`${name}[${index}].end`, defaultEnd)
    } else {
      setFieldValue(`${name}[${index}].end`, interval?.end || defaultEnd)
    }
  }

  const handleEndChange = (value: Date) => {
    if (interval.start) {
      const matchingInterval = timeForBooking.find((slot) =>
        isWithinInterval(interval.start, {
          start: slot.from,
          end: slot.to
        })
      )

      if (!matchingInterval) return

      if (isBefore(value, matchingInterval.from)) {
        value = addMinutes(interval.start, 30)
      } else if (isAfter(value, matchingInterval.to)) {
        value = matchingInterval.to
      }
    }

    setFieldValue(`${name}[${index}].end`, value)
  }

  const updateSlots = () => {
    setFieldValue(
      'dates',
      validateConstraintsSlot(
        validateIntervalSlots(values.dates.filter(isSlotValidPredicate)),
        timeForBooking,
        !adminRestricted
      )
    )
  }
  return (
    <IntervalContainer key={'dates ' + index}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <IntervalGrid>
            <IntervalBox>
              <FormLabel>{translate('start-datetime')}</FormLabel>
              <Field
                name={`${name}[${index}].start`}
                component={DatePickerField}
                $fullWidth
                openToDate={openStartDate}
                filterTime={filterStartTime}
                filterDate={filterStartDate}
                onChange={handleStartChange}
              />
            </IntervalBox>
            <IntervalBox>
              <FormLabel>{translate('end-datetime')}</FormLabel>
              <Field
                $fullWidth
                name={`${name}[${index}].end`}
                type="text"
                placeholder="Время окончания"
                openToDate={start || openStartDate}
                component={DatePickerField}
                filterTime={filterEndTime}
                filterDate={filterEndDate}
                onChange={handleEndChange}
              />
            </IntervalBox>
            <Buttons>
              {count > 0 && (
                <BtnWrapper type="button" onClick={() => helpers.remove(index)}>
                  <RemoveIcon color="#fff" />
                </BtnWrapper>
              )}
              {isDesktop && (
                <BtnWrapper
                  type="button"
                  onClick={() => {
                    updateSlots()
                    helpers.insert(values.dates.length, { start: '', end: '' })
                  }}
                >
                  <PlusIcon color="#fff" />
                </BtnWrapper>
              )}
            </Buttons>
          </IntervalGrid>
        </Grid>
      </Grid>
    </IntervalContainer>
  )
}

export default BookingInterval

const IntervalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 16px;
  width: 100%;
`

const IntervalContainer = styled.div`
  display: flex;
  width: 100%;
`

const IntervalBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 24px;
  flex-shrink: 0;
`
