import React from 'react'
import styled from 'styled-components'
import create from 'zustand'
import Modal from '@/components/Modal/Modal'
import Close from '@/components/ui/button/Close'
import Toolbar from '@/components/Toolbar/Toolbar'
import { Input } from '@/ui/components/Field/Input'
import { SelectInput, SelectField } from '@/ui/components/Field/Select'
import { FormControls } from '@/components/ui/form/FormControls'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { useState } from 'react'
import { Field, Formik } from 'formik'
import { bookingTypes } from '@/utils/constants/booking.constants'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useProjectStore } from '@/stores/projectStore'
import { useMemo } from 'react'
import { SingleDatePickerField } from '@/ui/components/Field/SingleDatePickerField'
import { ShowValid } from '@/components/shared/ShowValid'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { useEffect } from 'react'
import ReportData from './ReportData'
import { isAfter } from 'date-fns'
import EmployeesReportData from './EmployeesReportData'
import ObjectsReportData from './ObjectsReportData'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'

type CreateReportDialogStore = {
  isOpen: boolean
  onClose: () => void
}

export const useCreateReportDialogStore = create<CreateReportDialogStore>(
  (set) => ({
    id: undefined,
    isOpen: false,
    onClose: () => {
      set({
        isOpen: false
      })
    }
  })
)

export const createReportDialog = () => {
  useCreateReportDialogStore.setState({
    isOpen: true
  })
}

export const reportTypes = [
  { value: 'bookings', label: 'Бронирование' },
  { value: 'employees', label: 'Сотрудники' },
  { value: 'objects', label: 'Объекты' }
]

const headerTitle = {
  bookings: 'Отчет по бронированию',
  employees: 'Отчет по сотрудникам',
  objects: 'Отчет по объектам'
}

const CreateReportModal = () => {
  const isOpen = useCreateReportDialogStore((state) => state.isOpen)
  const onClose = useCreateReportDialogStore((state) => state.onClose)
  const nodes = useProjectStore((state) => state.nodes)
  const intl = useIntl()

  const [object, setObject] = useState('')
  const [reportSettings, setReportSettings] = useState<any>(null)

  useEffect(() => {
    if (isOpen) {
      setObject('')
      setReportSettings(null)
    }
  }, [isOpen])

  const { metadata } = useMetadata()
  const { data: userFields } = useUserCardFields()

  const layers =
    useMemo(
      () => nodes.map((node) => ({ value: String(node.id), label: node.name })),
      [nodes]
    ) || []
  const objectTypes = useProjectStore((state) => state.objectTypes)
  const userProps =
    useMemo(
      () =>
        userFields?.fields
          .filter((v) => v.alias != 'booking_category')
          .map((field) => ({ label: field.label, key: field.uid })),
      [userFields]
    ) || []
  const nodeProps =
    useMemo(() => getNodeProps(metadata, object), [object]) || []

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Header>
        <Title>
          {reportSettings == null
            ? translate('add-report')
            : translate('create-report-' + reportSettings.type)}
        </Title>
        <Close color="#000" onClick={onClose} />
      </Header>

      <ShowValid valid={reportSettings == null}>
        <Formik
          initialValues={{
            type: 'bookings',
            bookingType: '',
            startDate: new Date(),
            endDate: new Date(),
            objectType: '',
            layer: '',
            cols: [],
            bookable: null
          }}
          onSubmit={(values) => {
            if (!values.cols.length) {
              alert(intl.formatMessage({ id: 'select-data-to-display' }))
              return
            }

            setReportSettings(values)
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <Toolbar>
                  <Toolbar.Item xs={6} md={4}>
                    <Toolbar.BlackLabel>
                      {translate('select-report')}
                    </Toolbar.BlackLabel>
                    <Field
                      name="type"
                      component={SelectField}
                      $fullWidth
                      required={true}
                      options={reportTypes.map((item) => ({
                        ...item,
                        label: intl.formatMessage({ id: item.value })
                      }))}
                      onChange={(event) => {
                        const value = event.target.value
                        setFieldValue('type', value)
                        setFieldValue('cols', [])
                      }}
                    />
                  </Toolbar.Item>
                </Toolbar>

                <Toolbar>
                  <ShowValid valid={values.type === 'bookings'}>
                    <>
                      <Toolbar.Item xs={6} md={4}>
                        <Toolbar.BlackLabel>
                          {translate('start-date')}
                        </Toolbar.BlackLabel>
                        <Field
                          name="startDate"
                          component={SingleDatePickerField}
                          $fullWidth
                          required={true}
                          placeholder={intl.formatMessage({ id: 'start-date' })}
                          onChange={(date) => {
                            if (isAfter(date, values.endDate)) {
                              setFieldValue('endDate', date)
                            }
                          }}
                        />
                      </Toolbar.Item>
                      <Toolbar.Item xs={6} md={4}>
                        <Toolbar.BlackLabel>
                          {translate('end-date')}
                        </Toolbar.BlackLabel>
                        <Field
                          name="endDate"
                          component={SingleDatePickerField}
                          $fullWidth
                          required={true}
                          placeholder={intl.formatMessage({ id: 'end-date' })}
                          onChange={(date) => {
                            if (!isAfter(date, values.startDate)) {
                              setFieldValue('endDate', values.startDate)
                            }
                          }}
                        />
                      </Toolbar.Item>
                      <Toolbar.Item xs={6} md={4}>
                        <Toolbar.BlackLabel>
                          {translate('booking-type')}
                        </Toolbar.BlackLabel>
                        <Field
                          name="bookingType"
                          component={SelectField}
                          $fullWidth
                          placeholder={intl.formatMessage({
                            id: 'booking-type'
                          })}
                          options={[
                            {
                              value: '',
                              label: intl.formatMessage({ id: 'all' })
                            }
                          ].concat(
                            bookingTypes.map((item) => ({
                              ...item,
                              label: intl.formatMessage({ id: item.label })
                            }))
                          )}
                        />
                      </Toolbar.Item>
                    </>
                  </ShowValid>
                  <ShowValid
                    valid={
                      values.type === 'bookings' || values.type === 'places'
                    }
                  >
                    <>
                      <Toolbar.Item xs={6} md={4}>
                        <Toolbar.BlackLabel>
                          {translate('location')}
                        </Toolbar.BlackLabel>
                        <Field
                          name="layer"
                          component={SelectField}
                          $fullWidth
                          placeholder={intl.formatMessage({ id: 'location' })}
                          options={[
                            {
                              value: '',
                              label: intl.formatMessage({ id: 'all' })
                            }
                          ].concat(layers)}
                        />
                      </Toolbar.Item>
                      <Toolbar.Item xs={6} md={4}>
                        <Toolbar.BlackLabel>
                          {translate('object-type')}
                        </Toolbar.BlackLabel>
                        <Field
                          name="objectType"
                          component={SelectField}
                          $fullWidth
                          placeholder={intl.formatMessage({
                            id: 'object-type'
                          })}
                          options={[
                            {
                              value: '',
                              label: intl.formatMessage({ id: 'all' })
                            }
                          ].concat(objectTypes)}
                          onChange={(event) => {
                            const value = event.target.value

                            setFieldValue('objectType', value)
                            setObject(value)

                            // if (values.objectType != value) {
                            //     setFieldValue('cols', [])
                            // }
                          }}
                        />
                      </Toolbar.Item>
                    </>
                  </ShowValid>
                </Toolbar>

                <Toolbar>
                  <Toolbar.Item xs={12} md={12}>
                    <Toolbar.BlackLabel>
                      {translate('select-data-to-display')}
                    </Toolbar.BlackLabel>
                    <CheckboxContainer>
                      <ShowValid valid={values.type === 'bookings'}>
                        <>
                          <label>
                            <Field type="checkbox" name="cols" value="user" />
                            {translate('user')}
                          </label>
                          <label>
                            <Field type="checkbox" name="cols" value="name" />
                            {translate('place-name')}
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="cols"
                              value="objectType"
                            />
                            {translate('object-type')}
                          </label>
                          <label>
                            <Field type="checkbox" name="cols" value="layer" />
                            {translate('location')}
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="cols"
                              value="startDate"
                            />
                            {translate('start-date')}
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="cols"
                              value="endDate"
                            />
                            {translate('end-date')}
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="cols"
                              value="bookingType"
                            />
                            {translate('booking-type')}
                          </label>
                        </>
                      </ShowValid>

                      <ShowValid valid={values.type === 'employees'}>
                        <>
                          <label>
                            <Field
                              type="checkbox"
                              name="cols"
                              value="display"
                            />
                            {translate('user')}
                          </label>
                          {userProps.map((field) => (
                            <label>
                              <Field
                                type="checkbox"
                                name="cols"
                                value={field.key}
                              />
                              {field.label}
                            </label>
                          ))}
                        </>
                      </ShowValid>

                      <ShowValid valid={values.type === 'objects'}>
                        <>
                          <label>
                            <Field type="checkbox" name="cols" value="name" />
                            {translate('name')}
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="cols"
                              value="objectType"
                            />
                            {translate('object-type')}
                          </label>
                          <label>
                            <Field type="checkbox" name="cols" value="layer" />
                            {translate('location')}
                          </label>
                          <label>
                            <Field type="checkbox" name="bookable" />
                            Бронируемые
                          </label>
                          {/* {nodeProps.map(field => (
                                                        <label>
                                                            <Field type="checkbox" name="cols" value={'props-' + field.value} />{field.label}
                                                        </label>
                                                    ))} */}
                        </>
                      </ShowValid>
                    </CheckboxContainer>
                  </Toolbar.Item>
                </Toolbar>

                <ControlsWrapper>
                  <FormControls>
                    <PrimaryButton type="submit">
                      {translate('create-report')}
                    </PrimaryButton>
                    <DefaultButton type="button" onClick={onClose}>
                      {translate('cancel')}
                    </DefaultButton>
                  </FormControls>
                </ControlsWrapper>
              </Container>
            </form>
          )}
        </Formik>
      </ShowValid>

      <ShowValid
        valid={reportSettings !== null && reportSettings.type === 'bookings'}
      >
        <ReportData
          settings={reportSettings}
          onClose={onClose}
          layers={layers}
          objectTypes={objectTypes}
          nodeProps={nodeProps}
        />
      </ShowValid>

      <ShowValid
        valid={reportSettings !== null && reportSettings.type === 'employees'}
      >
        <EmployeesReportData
          settings={reportSettings}
          onClose={onClose}
          userProps={userProps}
        />
      </ShowValid>

      <ShowValid
        valid={reportSettings !== null && reportSettings.type === 'objects'}
      >
        <ObjectsReportData
          settings={reportSettings}
          onClose={onClose}
          layers={layers}
          objectTypes={objectTypes}
          nodeProps={nodeProps}
        />
      </ShowValid>
    </Modal>
  )
}

export default CreateReportModal

const getNodeProps = (meta, uid) => {
  if (!meta || !meta.rawNodes || !meta.rawNodes[uid]) {
    return []
  }
  const node = meta.rawNodes[uid]
  const pluginData = node.plugin_data
  const keys = Object.keys(pluginData)

  const data = keys
    .map((key) => node.plugin_data[key])
    .filter((v) => v)
    .find((item) => item.fields)?.fields

  if (!data) return []

  return data.map((field) => ({ value: field.id, label: field.name }))
}

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 16px;
  padding-top: 8px;

  label {
    display: flex;
    align-items: center;

    input {
      margin-right: 8px;
    }

    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
`
const ControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Container = styled.div``

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
`
