import React from 'react'

const SearchIcon = ({ width = 20, height = 19 }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 16C13.9853 16 16 13.9853 16 11.5C16 9.01472 13.9853 7 11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16ZM11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z" fill="#BDBDBD"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2929 15.293C15.6834 14.9025 16.3166 14.9025 16.7071 15.293L18.7071 17.293C19.0976 17.6836 19.0976 18.3167 18.7071 18.7073C18.3166 19.0978 17.6834 19.0978 17.2929 18.7073L15.2929 16.7073C14.9024 16.3167 14.9024 15.6836 15.2929 15.293Z" fill="#BDBDBD"/>
        </svg>
    )
}

export default SearchIcon