import { useProjectStore } from '@/stores/projectStore'
import React from 'react'
import DateSelector from '@/components/shared/map/map-controls/DataSelector'

const MapDateSelector = () => {
	const fullMode = useProjectStore((state) => state.fullMode)

	if (!fullMode) return null

	return <DateSelector />
}

export default MapDateSelector
