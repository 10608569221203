import Grid from '@/ui/components/Grid'
import PlusIcon from '@/components/icons/PlusIcon'
import { translate } from '@/i18n'
import React from 'react'
import styled from 'styled-components'
import media from '@/ui/media'

export const NoBookingIntervals = ({ helpers }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <AddButton
          type="button"
          onClick={() => helpers.push({ start: '', end: '' })}
        >
          <BtnWrapper as="div">
            <PlusIcon color="#fff" />
          </BtnWrapper>
          <BtnText>{translate('add-interval')}</BtnText>
        </AddButton>
      </Grid>
    </Grid>
  )
}

const AddButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;

  ${media.sm`
        border: 1px solid #1e22aa;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
    `}
`

export const BtnWrapper = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background: #1e22aa;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`

const BtnText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  margin-left: 12px;
  cursor: pointer;
`
