import jsPDF from "jspdf"
import 'jspdf-autotable'
import { formatToReport } from "../helpers/dates.helpers"
import { font, fontBold } from "./font"

type Column = {
    header: string
    dataKey: string
}

type PrintProps = {
    columns: Column[]
    body: any
    name: string | null
}

export const printPDF = ({
    columns = [],
    body,
    name = ''
}: PrintProps) => {
    const dateLabel  = `${formatToReport(new Date())}`
    const doc = new jsPDF('p', 'pt', 'a4')
    doc.addFileToVFS("Roboto-normal.ttf", font)
    doc.addFileToVFS("Roboto-bold.ttf", fontBold)
    doc.addFont("Roboto-normal.ttf", "Roboto", "normal")
    doc.addFont("Roboto-bold.ttf", "Roboto", "bold")
    doc.setFont("Roboto")
    

    // @ts-ignore
    doc.autoTable({
        theme: "plain",
        columns,
        body,
        styles: { font: "Roboto" }
    })

    doc.save(`${name + ' ' + dateLabel}.pdf`)
}