import SvgIcon from './svg-icon/SvgIcon'

const DraggableIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="1024px"
        height="1024px"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
      >
        <path d="M909.3 506.3L781.7 405.6a7.23 7.23 0 0 0-11.7 5.7V476H548V254h64.8c6 0 9.4-7 5.7-11.7L517.7 114.7a7.14 7.14 0 0 0-11.3 0L405.6 242.3a7.23 7.23 0 0 0 5.7 11.7H476v222H254v-64.8c0-6-7-9.4-11.7-5.7L114.7 506.3a7.14 7.14 0 0 0 0 11.3l127.5 100.8c4.7 3.7 11.7.4 11.7-5.7V548h222v222h-64.8c-6 0-9.4 7-5.7 11.7l100.8 127.5c2.9 3.7 8.5 3.7 11.3 0l100.8-127.5c3.7-4.7.4-11.7-5.7-11.7H548V548h222v64.8c0 6 7 9.4 11.7 5.7l127.5-100.8a7.3 7.3 0 0 0 .1-11.4z" />
      </svg>
    </SvgIcon>
  )
}

export default DraggableIcon
