import styled from "styled-components"
import Count from "./Count"

const Row = ({name, ...countProps}: { name: string, free: number, busy: number, total: number }) => {
  return (
    <RowWrapper>
      <RowName>{name}</RowName>
      <Count {...countProps} />
    </RowWrapper>
  )
}

const RowWrapper = styled.div`
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
	margin: 0.7rem 0;
`

const RowName = styled.div``

export default Row