import { FC } from 'react'
import styled from 'styled-components'

interface HintItemProps {
  icon: React.ReactNode
  text: string
}

const HintItem: FC<HintItemProps> = ({ icon, text }) => {
  return (
    <Wrapper>
      <IconWrap>{icon}</IconWrap>
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default HintItem

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 120px;
`

const IconWrap = styled.div`
  display: flex;
  width: 24px;
  min-width: 24px;
  aspect-ratio: 1/1;
`

const Text = styled.p`
  font-size: 12px;
`
