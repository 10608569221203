import { redirectSAML, StatusResponseEnum } from '@/api'
import { useMe } from '@/api/hooks/useMe'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useGlobalStore } from '@/stores/globalStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { useEffect, useState } from 'react'
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom'
import Preloader from '@/components/Preloader/Preloader'
import { ProjectService } from '@/api/services/project.service'
import { useToast } from './shared/toast/useToast'
import qs from 'query-string'
import { UserService } from '@/api/services/user.service'
import { useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { useProject } from '@/hooks/useProject'

function LoadingComponent() {
	return <Preloader show={true} />
}

const useAuth = () => {
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()
	const location = useLocation()
	const navigate = useNavigate()
	const queryParams = qs.parse(location.search)

	const currentUser = queryParams.user
	const hash = queryParams.hash
	const samlwst = queryParams.samlwst
	const intl = useIntl()

	// selectors
	const queryClient = useQueryClient()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isSAML, setIsSAML] = useState<string | null>(null)

	const fetchData = async (currUser, currHash) => {
		try {
			const authResponse = await UserService.auth({
				workspaceId: Number(workspaceId),
				login: currUser,
				password: currHash,
			})

			if (
				authResponse &&
				authResponse.data.status === StatusResponseEnum.Success
			) {
				if (authResponse.data.ws_auth?.wst) {
					const newQueries = { ...queryParams, user: null, hash: null }

					navigate(
						{
							search: qs.stringify(newQueries, { sort: false, skipNull: true }),
						},
						{ replace: true },
					)
					queryClient.resetQueries()
				}
			} else {
				setIsLoading(false)
				navigate('/')
				return
			}
		} catch (e) {
			enqueueToast(
				{
					title: intl.formatMessage({ id: 'error' }),
					message: intl.formatMessage({ id: 'error-load-metadata' }),
				},
				{ variant: 'error' },
			)
		}

		setIsLoading(false)
	}

	const fetchUser = async () => {
		try {
			const meta = await ProjectService.getMetadata({
				workspaceId: Number(workspaceId),
				projectId: Number(projectId),
			})
			const response = await UserService.getMe(Number(workspaceId))
			if (meta && meta.data.status === StatusResponseEnum.Success) {
				setIsLoading(false)

				return
			}
			if (response && response.data.status !== StatusResponseEnum.Success) {
				const authref = response.data.authref
				if (authref) {
					setIsSAML(authref)
				}
			}
		} catch (e) {
			console.error(e)
			// enqueueToast(
			// 	{
			// 		title: intl.formatMessage({ id: 'error' }),
			// 		message: intl.formatMessage({ id: 'error-load-metadata' }),
			// 	},
			// 	{ variant: 'error' },
			// )
		}

		setIsLoading(false)
	}

	useEffect(() => {
		if (currentUser && hash) {
			fetchData(currentUser, hash)
			localStorage.removeItem('saml')
		} else if (samlwst) {
			localStorage.setItem('token', String(samlwst))
			localStorage.setItem('saml', String('true'))
			const newQueries = { ...queryParams, samlwst: null }

			navigate(
				{
					search: qs.stringify(newQueries, { sort: false, skipNull: true }),
				},
				{ replace: true },
			)

			window.location.reload()
		} else {
			fetchUser()
		}
	}, [])

	useEffect(() => {
		if (isSAML) {
			redirectSAML(isSAML, workspaceId, projectId)
		}
	}, [isSAML])

	return { isLoading, isSAML }
}

const IFrameAuth = () => {
	const isAuth = useAuth()

	if (isAuth.isLoading || isAuth.isSAML) {
		return <LoadingComponent />
	}

	return <Outlet />
}

export default IFrameAuth

// http://localhost:3001/project/2/2?hash=df27a1e9f7475a9545cd87fb5f74a252061d7208a6f6dccdf7ab75d97a667a54&user=daniil&map=3