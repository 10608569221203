import { ILayerView, LayerService } from '@/api/services/layer.service'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { memo, useEffect, useMemo, useState } from 'react'
import { Image, Layer } from 'react-konva'
import useImage from 'use-image'

const MapCoverLayer = ({ view, url, sourceType }) => {
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const nodes = useProjectStore((state) => state.nodes)
  const setSize = useMapStore((state) => state.setSize)
  const setIsLoading = useMapStore((state) => state.setIsLoading)

  const [ownerId, setOwnderId] = useState(null)
  const imageURL = useMemo(() => getMapCoverImagePath(view, url), [ownerId])
  const node = useMemo(() => nodes.find((n) => n.id == activeLayer), [nodes])
  const [source, status] = useImage(imageURL, sourceType)

  // useEffect(() => {

  //     if (!node) return

  //     if (node.ownView) {
  //         if (source && status === 'loaded') {
  //             setSize([source.width, source.height])
  //             setIsLoading(false)
  //         } else {
  //             setIsLoading(true)
  //         }

  //         if (!view) {
  //             setIsLoading(true)
  //         } else {
  //             const { image: { owner_id } } = view
  //             if (owner_id) {
  //                 setOwnderId(owner_id)
  //             }
  //         }
  //     } else {
  //         // highlight polygon with selected layer
  //         console.log('highlight polygon ', node)
  //         setPolygon(node.id)
  //     }

  // }, [source, status, view, activeLayer, nodes])

  useEffect(() => {
    if (!view) {
      if (node && node.ownView) {
        setIsLoading(true)
      }
    } else {
      const {
        image: { owner_id }
      } = view
      if (owner_id) {
        setOwnderId(owner_id)
      }
    }
  }, [view, activeLayer])

  useEffect(() => {
    const predicate = source && status === 'loaded'

    if (predicate) {
      setSize([source.width, source.height])
      setIsLoading(false)
    } else {
      if (!node?.ownView) {
        setIsLoading(true)
      }
    }
  }, [source, status])

  // if (status === 'loading') return null

  return (
    <Layer id="cover-layer" listening={false}>
      <Image image={source} />
    </Layer>
  )
}

export default memo(MapCoverLayer)

MapCoverLayer.whyDidYouRender = true

export const getMapCoverImagePath = (view: ILayerView | undefined, url: string) => {
  if (!view) return ''

  return LayerService.getLayerImage({
    layerId: Number(view.image.owner_id),
    imageName: view.image.image_id,
    extension: view.image.extension,
    url
  })
}

// const getCoverLayerId = (layerId: number, layers: TreeNodeData[]) => {
//     const node = layers.find(layer => layer.id === layerId)
//     if (!node || node.ownView) return layerId

//     return Number(node.parent)
// }
