import React from 'react'
import styled from 'styled-components'
import MapDateSelector from './map-controls/MapDateSelector'
import MapContainer from './stage/MapContainer'
import MapController from './stage/MapController'
import MapStatus from './stage/MapStatus'
import MapTooltip from './tooltip/MapTooltip'

const Map: React.FC = () => {
  return (
    <MapWrapper>
      <MapContainer />
      <MapStatus />
      <MapDateSelector />
      <MapController />

      <MapTooltip />
    </MapWrapper>
  )
}

Map.whyDidYouRender = true

export default Map

const MapWrapper = styled.div`
  position: relative;
`
