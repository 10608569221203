import { addMinutes, format, parseISO } from "date-fns"
import { extractSchedule } from "../../../Bookings"
import { DeepBookingItem } from "@/api/services/deep.booking.service";
import { formatDateISO } from "./formatDateISO";

const weekdays = ["Вс.", "Пн.", "Вт.", "Ср.", "Чт.", "Пт.", "Сб."];

export const getRecurrentDates = (item: DeepBookingItem) => {
    const { gap } = item

    const gapToSlots = Array.isArray(gap) ? gap.join(',') : gap
    const schedule =  extractSchedule(gapToSlots.split(','))
    const offset = -(new Date()).getTimezoneOffset()
    const formatMinutes = formatOffsettedByMinutes(offset)

    const slots = schedule.map(({ start, end }) => `${weekdays[start.getDay()]} ${formatMinutes(start)} - ${formatMinutes(end)}`)
    return `${`${formatDateISO(item.start)} - ${formatDateISO(item.end)}`}\r${slots}`
} 

const formatOffsettedByMinutes = (offset: number) => (date: Date) => format(addMinutes(date, offset), 'HH:mm')