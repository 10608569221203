export const formatURL = (url: string) => {
    return url.replace(/([^:]\/)\/+/g, '$1')
}

export const formatLink = (url: string) => {
    // Удаляем лишние пробелы и слеши внутри строки
    url = url.replace(/\s+/g, '').replace(/\/+/g, '/')

    // Удаляем начальные и конечные пробелы
    url = url.trim()

    // Добавляем начальный слеш, если его нет
    if (url.charAt(0) !== '/') {
        url = '/' + url
    }

    return url
}