import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSettingsSelector } from './settings/use-settings-selector'

export const useProject = () => {
	const wId = useSettingsSelector((settings) => settings.workspaceId)
	const pId = useSettingsSelector((settings) => settings.projectId)
	const projects = useSettingsSelector((settings) => settings.projects, [])
	const params = useParams<{ workspaceId: string; projectId: string }>()
	const location = useLocation()

	const projectData = useMemo(() => {
		const searchParams = new URLSearchParams(location.search)
		const currentProject = projects.find(p => location.pathname.includes(p.slug))

		let newParams = currentProject ? {
			workspaceId: currentProject.workspace,
			projectId: currentProject.project
		} : params

		const { workId, projId } = getProjectParams(searchParams, newParams, { wId, pId })

		return {
			projectId: projId,
			workspaceId: workId,
		}
	}, [params, location.search, wId, pId, projects])

	return projectData
}



const getProjectParams = (searchParams, params, fallback) => {
	return {
		workId: params.workspaceId ? Number(params.workspaceId) : searchParams.get('workspaceId')
			? Number(searchParams.get('workspaceId'))
			: fallback.wId,
		projId: params.projectId ? Number(params.projectId) : searchParams.get('projectId')
			? Number(searchParams.get('projectId'))
			: fallback.pId
	}
}