import useImage from 'use-image'
import { Group, Image, Rect } from 'react-konva'

const lockIcon = `
    <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="200px"
        width="200px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
      </svg>
  `

const svgBlob = new Blob([lockIcon], { type: 'image/svg+xml;charset=utf-8' })
const url = URL.createObjectURL(svgBlob)

export const LockStatus = ({ size }) => {
  const [image] = useImage(url)

  if (!image) return null

  return (
    <Group y={0} x={-size / 2}>
      <Rect
        width={size}
        height={size}
        cornerRadius={9999}
        fill="#fff"
        shadowForStrokeEnabled={false}
        perfectDrawEnabled={false}
        listening={true}
        stroke="#000"
        strokeWidth={size * 0.1}
      />
      <Image
        image={image}
        x={(size - size * 0.8) / 2}
        y={(size - size * 0.8) / 2}
        width={size * 0.8}
        height={size * 0.8}
      />
    </Group>
  )
}
