import React from 'react'

const DeleteIcon = ({ width = 16, height = 18, color = 'white' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.47314 2.42859H5.31689C5.40283 2.42859 5.47314 2.35476 5.47314 2.26453V2.42859H11.4106V2.26453C11.4106 2.35476 11.481 2.42859 11.5669 2.42859H11.4106V3.90515H12.8169V2.26453C12.8169 1.5406 12.2563 0.952026 11.5669 0.952026H5.31689C4.62744 0.952026 4.06689 1.5406 4.06689 2.26453V3.90515H5.47314V2.42859ZM15.3169 3.90515H1.56689C1.22119 3.90515 0.941895 4.19841 0.941895 4.5614V5.21765C0.941895 5.30789 1.01221 5.38171 1.09814 5.38171H2.27783L2.76025 16.1073C2.7915 16.8066 3.34229 17.3583 4.0083 17.3583H12.8755C13.5435 17.3583 14.0923 16.8087 14.1235 16.1073L14.606 5.38171H15.7856C15.8716 5.38171 15.9419 5.30789 15.9419 5.21765V4.5614C15.9419 4.19841 15.6626 3.90515 15.3169 3.90515ZM12.7251 15.8817H4.15869L3.68604 5.38171H13.1978L12.7251 15.8817Z" fill={color} />
        </svg>
    )
}

export default DeleteIcon