import { ISettings } from '@/hooks/settings/settings.interface'

class AppSettings {
  settings: ISettings

  constructor() {
    this.settings = this._getSettings()
  }

  private _getSettings(): ISettings {
    const settings = window.SETTINGS

    if (typeof settings !== 'object' || settings === null) {
      alert('Error while parsing settings')
      console.error('Settings must be a non-null object')
      return {} as ISettings
    }

    return settings as ISettings
  }
}

export const $appSettings = new AppSettings()
