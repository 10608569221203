import React, { useEffect } from 'react'
import styled, { css } from 'styled-components';
import { useToast } from '@/components/shared/toast/useToast';
import { useTransition, animated } from 'react-spring';
import { easePoly } from 'd3-ease';

type ToastVariantOptions = 'success' | 'warning' | 'error' | 'default'

export type ToastOptionsType = {
    timeout?: number
    variant?: ToastVariantOptions
}

export type ToastContentType = {
    title: string
    message: string
}

interface ToastProps {
    id: string
    options: ToastOptionsType
    content: ToastContentType
}

const Toast: React.FC<ToastProps> = ({
    id,
    options = {},
    content,
}) => {
    const { timeout = 3000, variant = 'success' } = options
    const { dismissToast } = useToast()
    const { title, message } = content

    useEffect(() => {
        const timer = setTimeout(() => {
            dismissToast(id)
        }, timeout)

        return () => {
            clearTimeout(timer)
        }
    }, [id, dismissToast])

    const transitions = useTransition(id, {
        from: { opacity: 0, translateY: 100 },
        enter: { opacity: 1, translateY: 0 },
        leave: { opacity: 0, translateY: 100 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    return transitions((style, item) => item && (
        <Wrapper style={style} id={id} variant={variant}>
            {title && <Title>{title}</Title>}
            <Content>
                {message}
            </Content>
        </Wrapper>
    ))
}

export default Toast

const VariantSuccess = css`
    color: #4DB521;
`
const VariantWarning = css`
    color: #1e22aa;
`
const VariantError = css`
    color: #CA0000;
`
const VariantDefault = css`
    color: #AFAFAF;
`

const Title = styled.div`
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 6px;
`

const Content = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #AFAFAF;
`

const Wrapper = styled(animated.div)<{ variant: ToastVariantOptions }>`
    margin-right: 16px;
    margin-bottom: 16px;
    padding: 10px;
    position: relative;
    padding: 16px;
    border-radius: 3px;
    background: #333333;
    border-radius: 5px;
    /* box-shadow: 0px 4px 10px 0px #d7d7d7; */
    color: #fff;
    max-width: 300px;
    width: 100%;

    ${({ variant }) => {
        switch(variant) {
            case 'success': return VariantSuccess
            case 'error': return VariantError
            case 'warning': return VariantWarning
            default: return VariantDefault
        }
    }}
`