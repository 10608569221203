import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Group, Rect, Text } from "react-konva";
import { useMapStore } from "@/stores/mapStore";
import { useGlobalStore } from "@/stores/globalStore";
import { getMapCoord } from "@/components/shared/map/polygon/Polygon";
import { differenceInMilliseconds, isAfter, isBefore } from "date-fns";
import {formatToISOTimezone} from "@/utils/helpers/dates.helpers";

const calculateLoad = (bookings, selected) => {
  if (!bookings?.length) return null;

  let globalStart: Date | null = null;
  let globalEnd: Date | null = null;

  // console.log(bookings, selected);

  bookings.forEach((booking) => {
    const { start: startTime, end: endTime } = booking;
    const start = startTime ? formatToISOTimezone(startTime) : startTime
    const end = endTime ? formatToISOTimezone(endTime) : endTime
    console.log('start', end, startTime, endTime)

    if (!globalStart) {
      globalStart = new Date(start);
    } else if (isBefore(new Date(start), globalStart)) {
      globalStart = new Date(start);
    }

    if (!globalEnd) {
      if (end) {
        globalEnd = new Date(end);
      }
    } else if (globalEnd && end && isAfter(new Date(end), globalEnd)) {
      globalEnd = new Date(end);
    }
  });

  if (!globalStart) return null;

  if (isBefore(globalStart, selected.startDate)) {
    globalStart = selected.startDate;
  }

  if (!globalEnd) {
    globalEnd = selected.endDate;
  } else if (isAfter(globalEnd, selected.endDate)) {
    globalEnd = selected.endDate;
  }

  if (!globalEnd || !globalStart) return 0;

  console.log(globalStart, globalEnd)

  return (
    (differenceInMilliseconds(globalEnd, globalStart) /
      differenceInMilliseconds(selected.endDate, selected.startDate)) *
    100
  );
};

const loadColors = {
  high: "#AE0000",
  moderate: "#F3BB5E",
  low: "#1e22aa",
  unavailable: "#D9D9D9",
};

const getLoadColor = (load) => {
  if (load > 70) return loadColors["high"];
  if (load <= 70 && load >= 30) return loadColors["moderate"];
  return loadColors["low"];
};

const LoadPoint = (props) => {
  const { point, options, bookingData, allBookings, available } = props;

  const selected = useGlobalStore((state) => state.selection);
  const setSeat = useGlobalStore((state) => state.setSeat);
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee);
  const [width, height] = useMapStore((state) => state.size);
  const setTooltip = useMapStore((state) => state.setTooltip);

  const textRef = useRef<any>(null);
  const groupRef = useRef<any>(null);
  const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 });

  const { id, x, y, name, type_uid, plugin_data } = point;
  const { labelSize, fontSize, color, borderWidth, wrapText } = options;

  const displayText = wrapText ? name.split(" ").join("\n") : name;

  const load = useMemo(
    () => calculateLoad(allBookings, selected),
    [allBookings, selected]
  );

  if (load) {
    console.log(load);
  }

  const onSelect = useCallback(() => {
    setSeat(Number(point.id));
    if (bookingData) {
      setSeatEmployee(Number(bookingData.user_id));
    } else {
      setSeatEmployee(null);
    }
  }, [setSeat, setSeatEmployee, bookingData, point]);

  const onMouseEnterHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container();

    if (container) {
      container.style.cursor = "pointer";
    }
  }, []);

  const onMouseLeaveHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container();

    if (container) {
      container.style.cursor = "default";
    }
  }, []);

  const onGroupMouseEnterHandler = React.useCallback(
    (e) => {
      setTooltip(name);
    },
    [setTooltip, name]
  );

  const onGroupMouseLeaveHandler = React.useCallback(
    (e) => {
      setTooltip(null);
    },
    [setTooltip]
  );

  const pointColor = useMemo(
    () => (available ? getLoadColor(load) : loadColors["unavailable"]),
    [available, load]
  );

  useEffect(() => {
    if (width > 0 && height > 0) {
      const pointX =
        -textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
        (width * labelSize) / 2;
      setCoord({
        x: getMapCoord(width, x),
        y: getMapCoord(height, y),
        pX: Number(pointX),
      });
    }
  }, [textRef.current, width, height, displayText]);

  useEffect(() => {
    if (!groupRef.current) return;
    groupRef.current.on("mouseenter", onGroupMouseEnterHandler);
    groupRef.current.on("mouseleave", onGroupMouseLeaveHandler);
  }, [groupRef.current]);

  return (
    <Group
      x={coord.x}
      y={coord.y}
      offsetX={(width * labelSize) / 2}
      offsetY={(width * labelSize) / 2}
      onClick={onSelect}
      onTap={onSelect}
      listening={true}
    >
      <Group
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        id={"point" + point.id}
        ref={groupRef}
      >
        <Rect
          width={width * labelSize}
          height={width * labelSize}
          stroke={pointColor}
          strokeWidth={fontSize * borderWidth * 0.5}
          fill="transparent"
          cornerRadius={9999}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={available}
        />
        <Rect
          x={width * labelSize * 0.2}
          y={width * labelSize * 0.2}
          width={width * labelSize * 0.6}
          height={width * labelSize * 0.6}
          stroke={pointColor}
          strokeWidth={fontSize * borderWidth}
          fill={pointColor}
          cornerRadius={9999}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={available}
        />
      </Group>
      <Text
        ref={textRef}
        text={displayText}
        align="center"
        fontSize={fontSize}
        fill={color}
        y={width * 1.1 * labelSize}
        x={coord.pX}
        listening={available}
        perfectDrawEnabled={false}
      />
    </Group>
  );
};

export default React.memo(LoadPoint);
