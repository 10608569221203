import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import {
  ControlsWrapper,
  FormControls
} from '@/components/ui/form/FormControls'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Pagination from '@/ui/components/Pagination/Pagination'
import useResponsive from '@/hooks/useResponsive'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import { CSVLink } from 'react-csv'
import { printPDF } from '@/utils/func/print'
import MobileReportItem from './MobileReportItem'
import { useUsers } from '@/api/hooks/useUsers'
import { translate } from '@/i18n'
import { useIntl } from 'react-intl'

type IColumn = {
  label: string
  key: string
  alias: string
}

const employeeHeaders: IColumn[] = [
  { label: 'Пользователь', key: 'display', alias: 'user' }
]

const EmployeesReportData = ({ settings, onClose, userProps }) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { isDesktop } = useResponsive()
  const intl = useIntl()

  console.log(userProps)

  const { data } = useUsers({ page: currentPage, perPage: 20 })

  const columns = employeeHeaders
    .concat(userProps)
    .filter((header) => settings.cols.includes(header.key))
    .map((item) => ({
      ...item,
      label: intl.formatMessage({ id: item.label })
    }))
  const pdfCols = useMemo(
    () => columns.map((col) => ({ header: col.label, dataKey: col.key })),
    [columns]
  )
  const dataItems = useMemo(() => data?.items, [data]) || []

  return (
    <ReportDataContainer>
      <Table>
        {isDesktop && (
          <TableHead>
            <tr>
              {columns.map((header) => (
                <TableHeadCell key={header.key}>{header.label}</TableHeadCell>
              ))}
            </tr>
          </TableHead>
        )}

        <TableBody>
          {dataItems && dataItems.length ? (
            dataItems
              .slice((currentPage - 1) * 20, currentPage * 20)
              .map((item) => (
                <ElementItem columns={columns} key={item.id} item={item} />
              ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <NotFound>{translate('no-results')}</NotFound>
              </td>
            </tr>
          )}
        </TableBody>
      </Table>

      <Pagination
        inverse
        currentPage={currentPage}
        total={dataItems.length || 0}
        handlePageChange={setCurrentPage}
      />

      <ControlsWrapper>
        <FormControls>
          <PrimaryButton
            type="button"
            onClick={() =>
              printPDF({
                name:
                  intl.formatMessage({ id: 'create-report-' + settings.type }) +
                  ` ${formatToReport(new Date())}`,
                columns: pdfCols,
                body: dataItems
              })
            }
          >
            {translate('save-pdf')}
          </PrimaryButton>
          <CSVLink
            data={dataItems}
            headers={columns}
            separator={';'}
            filename={
              intl.formatMessage({ id: 'create-report-' + settings.type }) +
              ` ${formatToReport(new Date())}`
            }
          >
            <PrimaryButton type="button">{translate('save-csv')}</PrimaryButton>
          </CSVLink>
          <DefaultButton type="button" onClick={onClose}>
            {translate('cancel')}
          </DefaultButton>
        </FormControls>
      </ControlsWrapper>
    </ReportDataContainer>
  )
}

export default EmployeesReportData

const ReportDataContainer = styled.div`
  margin-top: 32px;
`

const TableHeadCell = styled.th`
  text-align: left;
  padding: 5px 10px 5px 0;
`

const TableBody = styled.tbody``

const TableHead = styled.thead`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`

const Table = styled.table`
  margin: 1rem 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  tr td:last-child {
    width: 1%;
    white-space: nowrap;
  }
`

const TableCell = styled.td`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  padding: 5px 10px 5px 0;
`

const ElementItem: React.FC<{ item: any; columns: IColumn[] }> = ({
  item,
  columns
}) => {
  const { isDesktop } = useResponsive()

  if (!isDesktop) return <MobileReportItem columns={columns} data={item} />

  return (
    <tr>
      {columns.map((col) => (
        <TableCell key={item.id}>{item[col.key] || ''}</TableCell>
      ))}
    </tr>
  )
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`
