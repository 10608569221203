import { BasicPointProps } from '@/components/shared/map/point/basic/basic-point.interface'
import { API } from '@/api'
import {
  defaultMapPointImage,
  getImageLink
} from '@/components/shared/map/point/Point'
import useImage from 'use-image'

export const useMapAvatar = (
  bookingData: BasicPointProps['bookingData'],
  node: BasicPointProps['node'],
  url: string,
  sourceType: 'anonymous' | 'use-credentials'
) => {
  const imgSrc = bookingData
    ? API.user.getAvatar({ userId: Number(bookingData.user_id), url })
    : getImageLink(node?.icon, url) || getImageLink(defaultMapPointImage, url)
  const [pointImg] = useImage(imgSrc, sourceType)

  return { image: pointImg }
}
