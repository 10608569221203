import React from 'react'
import styled from 'styled-components'
import HintChairIcon from '../icons/HintChairIcon'
import HintItem from './HintItem'
import FreeSeatIcon from '../icons/FreeSeatIcon'
import HintAvatarIcon from '../icons/HintAvatar'
import { useMediaQuery } from 'react-responsive'

const HintMap = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <Wrapper>
      {!isMobile ? <HintChairIcon /> : null}
      <HintItem text="Свободное рабочее место" icon={<FreeSeatIcon />} />
      <HintItem text="Занятое рабочее место" icon={<HintAvatarIcon />} />
    </Wrapper>
  )
}

export default HintMap

const Wrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 100px;
  display: flex;
  gap: 12px;
  border-radius: 8px;
  background-color: #eef4fc;
  padding: 16px;

  @media screen and (max-height: 420px) {
    bottom: 70px;
    right: 70px;
  }

  @media screen and (max-width: 1024px) {
    bottom: 90px;
  }
`
