import { SectionType } from '@/containers/SidebarContainer'
import { endOfDay, startOfDay } from 'date-fns'
import create from 'zustand'

type GlobalState = {
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
  project: {
    projectId: number
    workspaceId: number
  }
  setProject: (project) => void
  activeLayer: number | null
  setActiveLayer: (layer: number | null) => void
  seat: number | null
  setSeat: (seat: number | null) => void
  seatEmployee: number | null
  setSeatEmployee: (seat: number | null) => void
  booking: number | string | null
  setBooking: (booking: number | string | null) => void
  selector: SectionType | null
  setSelector: (selector: SectionType | null) => void
  employee: number | null
  setEmployee: (seat: number | null) => void
  layerModal: number | null
  popupLayer: number | null
  setLayerModal: (layer: number | null) => void
  setPopupLayer: (popupLayer: number | null) => void
  selection: {
    startDate: Date
    endDate: Date
    key: 'selection'
  }
  setSelection: (start: Date, end: Date) => void
  reset: () => void
}

const initialState: any = {
  isLoading: true,
  activeLayer: null,
  seat: null,
  seatEmployee: null,
  booking: null,
  selector: null,
  employee: null,
  layerModal: null,
  popupLayer: null,
  selection: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection'
  },
}

export const useGlobalStore = create<GlobalState>((set) => ({
  ...initialState,

  setLoading: (isLoading) => set(() => ({ isLoading })),
  setProject: (project) => set(() => ({ project })),
  setActiveLayer: (activeLayer) => set(() => ({ activeLayer })),
  setSeat: (seat) =>
    set(() => ({ seat, selector: null, employee: null, layerModal: null })),
  setSeatEmployee: (seatEmployee) =>
    set(() => ({
      seatEmployee,
      selector: null,
      employee: null,
      layerModal: null
    })),
  setBooking: (booking) => set(() => ({ booking })),
  setSelector: (selector) =>
    set(() => ({ selector, seat: null, employee: null, layerModal: null })),
  setEmployee: (employee) =>
    set(() => ({ employee, selector: null, seat: null, layerModal: null })),
  setLayerModal: (layerModal) =>
    set(() => ({ layerModal, selector: null, seat: null, employee: null })),
  setPopupLayer: (popupLayer) =>
    set(() => ({
      popupLayer,
      layerModal: null,
      selector: null,
      seat: null,
      employee: null
    })),
  setSelection: (startDate: Date, endDate: Date) =>
    set(() => ({
      selection: {
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
      }
    })),
    reset: () => set(() => ({ ...initialState }))
}))
