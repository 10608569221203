import MapPointIcon from '@/components/icons/MapPointIcon'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { useProject } from '@/hooks/useProject'
import media from '@/ui/media'
import { formatLink } from '@/utils/helpers/format-url'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const OfficeSelector = () => {
  const projects = useSettingsSelector((settings) => settings.projects, [])
  const project = useProject()
  const wrapRef = useRef<HTMLDivElement | null>(null)
  const [activeSwitch, setActiveSwitch] =
    useState<RefObject<HTMLAnchorElement> | null>(null)

  const rollerRect = useMemo(() => {
    if (activeSwitch?.current && wrapRef?.current) {
      const activeSwitchRect = activeSwitch.current.getBoundingClientRect()
      const wrapRect = wrapRef.current.getBoundingClientRect()
      const rect = {
        left: Math.trunc(activeSwitchRect.left - wrapRect.left),
        width: Math.trunc(activeSwitchRect.width)
      }

      return rect
    }
  }, [activeSwitch, wrapRef])

  return (
    <Wrap>
      <MapPointIcon />
      <LinksWrapper ref={wrapRef}>
        {rollerRect ? (
          <Roller
            style={{
              width: rollerRect.width,
              left: rollerRect.left
            }}
          ></Roller>
        ) : null}
        {projects
          .filter((p) => p.selector)
          .map((p) => {
            const switchRef = useRef<HTMLAnchorElement>(null)
            const isActive =
              project.projectId == p.project &&
              project.workspaceId == p.workspace

            useEffect(() => {
              if (isActive) {
                setActiveSwitch(switchRef)
              }
            }, [switchRef])

            const handleClick = () => {
              setActiveSwitch(switchRef)
            }

            return (
              <Link
                ref={switchRef}
                to={
                  formatLink(p.slug) ?? `/project/${p.workspace}/${p.project}`
                }
                onClick={(e) => e.stopPropagation()}
              >
                <Button $active={isActive} onClick={handleClick}>
                  <div>{p.name}</div>
                </Button>
              </Link>
            )
          })}
      </LinksWrapper>
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Roller = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% - 2px);
  width: 80px;
  background-color: #fff;
  border-radius: 8px;
  transition: 0.3s;
  pointer-events: none;
`

const LinksWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* gap: 16px; */
  margin-right: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  a {
    text-decoration: none;
  }
`

const Button = styled.div<{ $active: boolean }>`
  position: relative;
  z-index: 1;
  font-weight: 700;
  padding: 8px 12px;
  border-radius: 8px;
  text-decoration: none;
  outline: none;
  height: 40px;

  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.3s;

  ${media.lg`
        height: 32px;
        font-size: 12px;
        svg {
            width: 16px;
        }
    `}

  ${(props) =>
    props.$active
      ? css`
          color: #1f22a3;
        `
      : css`
          color: #fff;
        `}
`
