import styled from "styled-components"

const Count = ({free, busy, total}: { free: number | string, busy: number | string, total: number | string }) => {
  return (
    <CountWrapper>
      <CountFree>{free}</CountFree>/
      <CountBusy>{busy}</CountBusy>/
      <CountTotal>{total}</CountTotal>
    </CountWrapper>
  )
}

const CountWrapper = styled.div`
	font-size: 1.4rem;
	display: flex;
	column-gap: 2px;
`

const CountFree = styled.span`
  color: #4DB521;
`
const CountBusy = styled.span`
  color: #CA0000;
`
const CountTotal = styled.span``

export default Count