import IFrameAuth from '@/components/IFrameAuth'
import NotFound from '@/components/NotFound'
import ProtectedRoutes from '@/components/ProtectedRoutes'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import LogoutPage from '@/pages/logout'
import '@/settings.json'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginContainer from './LoginContainer'
import ProjectContainer from './ProjectContainer'

const AppContainer = () => {
  const projects = useSettingsSelector((settings) => settings.projects)

  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<IFrameAuth />}>
          <Route path="/login" element={<LoginContainer />} />
          <Route element={<ProtectedRoutes />}>
            <Route index element={<ProjectContainer />} />
            <Route
              path="/project/:workspaceId/:projectId"
              element={<ProjectContainer />}
            />

            {projects
              ?.filter((p) => p.slug)
              .map((project, idx) => (
                <Route
                  key={project.slug + idx}
                  path={project.slug}
                  element={<ProjectContainer />}
                />
              ))}
          </Route>
          <Route path="/logout" element={<LogoutPage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppContainer
