import styled from "styled-components"
import { CSVLink } from 'react-csv';
import media from '@/ui/media';
import Close from '@/components/Close';
import { ReportButton } from '../../ReportView';
import { DeepBookingItem } from "@/api/services/deep.booking.service";
import { getBookingType } from "./getBookingType";
import { getRecurrentDates } from "./getRecurrentDates";
import { formatDateISO } from "./formatDateISO";
import { bookingTypeTranslate } from "../../../Bookings";
import { BookingTableColumn, BookingTableKey } from "./BookingTableColumn";
import { printPDF } from "@/utils/func/print";
import { formatToReport } from "@/utils/helpers/dates.helpers";

export type BookingTableHeaders = BookingTableColumn & { label: string }

type ExportProps = { 
    report: string | null,
    onClose: () => void,
    headers: BookingTableHeaders[],
    items: DeepBookingItem[]
}

export const Export = ({ report, onClose, headers, items }: ExportProps) => {
	return (
		<ExportWrapper>
			<ReportButton
				onClick={() =>
					printPDF({
						name: report,
						columns: pdfCols(headers),
						body: reportFileData(items, headers),
					})
				}
			>
				PDF
			</ReportButton>
			<CSVLink
				data={reportFileData(items, headers)}
				headers={headers}
				separator={';'}
				filename={report + ` ${formatToReport(new Date())}`}
			>
				<ReportButton>CSV</ReportButton>
			</CSVLink>
			<Close color="#000" onClick={onClose} />
		</ExportWrapper>
	)
}

const pdfCols = (columns: BookingTableHeaders[]) => 
    columns.map(({ label, key }) => ({ header: label, dataKey: key }))

const reportFileData = (items: DeepBookingItem[], columns: BookingTableColumn[]) => items.map(item => {
    const entries = columns.map(({ key }) => [key, reportFileRowData(item, key)])
    return Object.fromEntries(entries)
})

const reportFileRowData = (item: DeepBookingItem, key: BookingTableKey) => 
    key === 'date'
        ? reportFileDate(item)
        : key === 'booking_type'
        ? reportFileBookingType(item)
        : key.startsWith('user.')
        ? item.user[key.slice('user.'.length)]
        : item[key]

const reportFileDate = (item: DeepBookingItem) =>
    getBookingType(item) !== 'recurrent' 
        ? `${formatDateISO(item.start)} ${getBookingType(item) !== 'constant' ? `- ${formatDateISO(item.end + '.000Z')}` : ''}` 
        : getRecurrentDates(item)

const reportFileBookingType = (item: DeepBookingItem) => bookingTypeTranslate[getBookingType(item)]

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
      &:not(:last-child) {
          margin-right: 0.8rem;
      }
  }

  
  ${media.lg`
      margin-top: 20px;
  `}
`