type MergeMessageParams = {
  url: string
  webUrl: string
  place: string
  seat: string
  created: string
  reason: string
  message: string
}

export const mergeMessage = (params: MergeMessageParams) => {
  return [
    `Сформирована заявка на обслуживание в YADRO карта офиса - <a href="${params.url}">${params.webUrl}</a>`,
    `Строение/Этаж: ${params.place}`,
    `Объект/номер места: ${params.seat}`,
    `Запросил: ${params.created}`,
    `Причина: ${params.reason}`,
    `Комментарий: ${params.message}`
  ].join('.<br />')
}
