import { OPEN_MODAL } from '@/components/shared/modal/modalUtils'

const ModalService = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail))
    },
    open(component, props = {}) {
        const event = new CustomEvent(OPEN_MODAL, {
            detail: {
                component,
                props
            }
        })
        document.dispatchEvent(event)
    }
}

export default ModalService