import React, { useState } from 'react';
import { useMetadata } from '@/api/hooks/useMetadata';
import Toolbar from '@/components/Toolbar/Toolbar';
import { format } from 'date-fns';
import { Input } from '@/ui/components/Field/Input';
import { translate } from '@/i18n';
import { BookingTableColumn } from './BookingTableColumn';
import { DeepBookingItem } from '@/api/services/deep.booking.service';
import { DeepBookingsQuery } from '@/api/hooks/useDeepBookings';
import { useIntl } from 'react-intl';
import ReportDateSelector from '../../ReportDateSelector';
import { SelectInput } from '@/ui/components/Field/Select';

type ReportToolbarProps = {
    columns: BookingTableColumn[],
    items: DeepBookingItem[],
    query: DeepBookingsQuery,
    setQuery: (query: DeepBookingsQuery) => void
}

const DATE_FORMAT = "dd.MM.yyyy HH:mm"

export const ReportToolbar = ({ columns, items, query, setQuery }: ReportToolbarProps) => {
    const intl = useIntl()
    const { metadata } = useMetadata()
    const [toggle, setOpen] = useState<boolean>(false)

    const handleTypeChange = (e) => setQuery({ ...query, placeType: e?.target?.value })

    const selection = { startDate: query.start ?? new Date(), endDate: query.end ?? new Date(), key: "selection" }

    return (
        <Toolbar>
            <Toolbar.Item xs={6} md={4}>
                <Toolbar.BlackLabel>{translate('date')}</Toolbar.BlackLabel>
                <Input
                    $fullWidth
                    placeholder={intl.formatMessage({ id: 'start-datetime' })}
                    value={`${format(selection.startDate, DATE_FORMAT)} - ${format(selection.endDate, DATE_FORMAT)}`}
                    onClick={() => setOpen(true)}
                />
                <ReportDateSelector
                    open={toggle}
                    setOpen={setOpen}
                    selection={selection}
                    setSelection={({ startDate: start, endDate: end }) => setQuery({ ...query, start, end })}
                />
            </Toolbar.Item>
            <Toolbar.Item xs={6} md={4}>
                <Toolbar.BlackLabel>{translate('object-types')}</Toolbar.BlackLabel>
                <SelectInput $fullWidth defaultValue={query.placeType} onChange={handleTypeChange}>
                    <option value="">{translate('all')}</option>
                    {metadata?.nodes?.map(node => <option key={node.uid} value={node.uid}>{node.name}</option>)}
                </SelectInput>
            </Toolbar.Item>
        </Toolbar>
    )
}