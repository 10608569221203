import AbstractBookingsReport from "./abstract-bookings-report/AbstractBookingsReport";


export const CompanyBookingsReport = AbstractBookingsReport([
    { key: "user.display", alias: "report-full-name" },
    { key: "user.company", alias: "report-company" },
    { key: "user.department", alias: "report-department" },
    { key: "user.title", alias: "report-user-position" },
    { key: "name", alias: "report-object" },
    { key: "date", alias: "report-date" },
])
