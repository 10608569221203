import { UserService } from '@/api/services/user.service'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { translate } from '@/i18n'
import { Field, Formik } from 'formik'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserStore } from './../stores/userStore'
import { InputField } from './../ui/components/Field/Input'
import { FormGroup } from './../ui/components/Form/FormGroup'
import { FormLabel } from './../ui/components/Form/FormLabel'
import { PrimaryButton } from './ui/button/PrimaryButton'

type LoginFormProps = {
  workspaceId: string | null | undefined
  projectId: string | null | undefined
}

const LoginForm: React.FC<LoginFormProps> = ({ workspaceId, projectId }) => {
  const router = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
	const wId = useSettingsSelector((settings) => settings.workspaceId)
	const pId = useSettingsSelector((settings) => settings.projectId)
	const projects = useSettingsSelector((settings) => settings.projects, [])

  const setUser = useUserStore((state) => state.setUser)
  const setRole = useUserStore((state) => state.setRole)

  const handleLoginSubmit = async (
    {
      login,
      password,
      workspaceId
    }: { login: string; password: string; workspaceId: string },
    { setSubmitting, setErrors }
  ) => {
    setSubmitting(true)
    try {
      const { data } = await UserService.auth({
        login,
        password,
        workspaceId: Number(workspaceId)
      })

      if (data?.ws_auth?.wst) {
        // localStorage.setItem('token', data.ws_auth.wst)
        setUser(data.me_data.ui)
        // if (data.me_data.ui.role === RolesEnum.Admin) {
        //     setRole(RolesEnum.Admin)
        // }
        localStorage.removeItem('saml')
        queryClient.resetQueries()

        const currentProject = projects.find(p => p.workspace == Number(workspaceId) && p.project == Number(projectId))

        if (currentProject) {
          const link = currentProject.slug ? `/${currentProject.slug}` : `/project/${currentProject.workspace}/${currentProject.project}`
          router(link)
        } else if (workspaceId == wId && projectId == pId) {
          router('/')
        } else {
          router(`/project/${workspaceId}/${projectId}`)
        }
      } else {
        alert('Unable to login')
      }
    } catch {
      alert('Unable to login')
    }
    setSubmitting(false)
  }

  if (!workspaceId) {
    return <div>No workspace ID passed</div>
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleLoginSubmit}
      initialValues={{
        login: '',
        password: '',
        workspaceId: workspaceId
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>{translate('auth_login')}</FormLabel>
            <FormattedMessage id="auth_login">
              {(placeholder) => (
                <Field
                  name="login"
                  $fullWidth
                  type="text"
                  placeholder={placeholder}
                  component={InputField}
                />
              )}
            </FormattedMessage>
          </FormGroup>

          <FormGroup>
            <FormLabel>{translate('auth_password')}</FormLabel>
            <FormattedMessage id="auth_password">
              {(placeholder) => (
                <Field
                  name="password"
                  $fullWidth
                  type="password"
                  placeholder={placeholder}
                  component={InputField}
                />
              )}
            </FormattedMessage>
          </FormGroup>

          <FormGroup>
            <PrimaryButton type="submit">{translate('log-in')}</PrimaryButton>
          </FormGroup>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
