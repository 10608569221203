import React from 'react'

const HintAvatarIcon = () => {
  return (
    <svg
      style={{ width: '100%', height: '100%' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 13C15.3137 13 18 10.3137 18 7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7C6 10.3137 8.68629 13 12 13Z"
        fill="#C5C5C5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 13C8.24148 13 5 16.4677 5 21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21C3 15.5911 6.92196 11 12 11C17.078 11 21 15.5911 21 21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21C19 16.4677 15.7585 13 12 13Z"
        fill="#C5C5C5"
      />
    </svg>
  )
}

export default HintAvatarIcon
