import styled from "styled-components"
import { ErrorBoundary } from 'react-error-boundary';
import { DeepBookingItem } from "@/api/services/deep.booking.service"
import { BookingDate } from '../../../Bookings';
import React from "react";
import { BookingTableColumn, BookingTableKey } from "./BookingTableColumn";

export const TableItem: React.FC<{ item: DeepBookingItem, columns: BookingTableColumn[] }> = ({ item, columns }) => {
    return (<tr>{columns.map(({ key }) => Cell(key, item))}</tr>)
}

const Cell = (key: BookingTableKey, item: DeepBookingItem) => {
    if (key === "date") return DateCell(item.rec, item.gap, item.start, item.end)
    if (key.startsWith("user.")) {
        const userKey = key.slice("user.".length)
        if (!(userKey in item.user)) return (<TableCell></TableCell>)
        return (<TableCell>{item.user[userKey]}</TableCell>)
    }
    if (!(key in item)) return (<TableCell></TableCell>)
    return (<TableCell>{item[key]}</TableCell>)
}

const DateCell = (rec: string, gap: string, start: string, end: string) => {
    const bookingType = rec != '0' ? 'recurrent' : end ? 'common' : 'constant'
    return (
        <TableCell>    
            <ErrorBoundary fallback={<div>Ошибка отображения даты</div>}>
                <BookingDate type={bookingType} gap={gap} inverse={true} date={{ start, end }} />
            </ErrorBoundary>
        </TableCell>
    )
}

const TableCell = styled.td`
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    border: 1px solid #dddddd;
	padding: 5px;
    vertical-align: middle;
`