import { useParams } from 'react-router-dom';
import { IUser, UserService } from '../services/user.service';
import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';

export const useMe = () => {
    const { workspaceId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['user', workspaceId],
        () => UserService.getMe(workspaceId),
        {
            enabled: !!workspaceId,
            select: ({ data }): IUser => data?.me_data?.ui,
            onError: ({ message }) => {
                enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' })
            }
        }
    )

    return { user: data, isLoading, refetch }
}