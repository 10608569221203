import React from 'react'

const HintChairIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.58313 1.88235C9.58313 0.842759 10.4259 0 11.4655 0H20.535C21.5746 0 22.4173 0.842757 22.4173 1.88235V7.78609H20.535L20.535 1.88235L11.4655 1.88235V7.78609H9.58313V1.88235Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.21375 29.2617C8.21375 28.2222 9.0565 27.3794 10.0961 27.3794H21.9036C22.9432 27.3794 23.7859 28.2222 23.7859 29.2617V31.0585C23.7859 31.5783 23.3645 31.9997 22.8448 31.9997C22.325 31.9997 21.9036 31.5783 21.9036 31.0585L21.9036 29.2617H10.0961V29.2617V31.0585C10.0961 31.5783 9.67472 31.9997 9.15492 31.9997C8.63512 31.9997 8.21375 31.5783 8.21375 31.0585V29.2617Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0961 17.3691V8.72743L21.9036 8.72743V17.3691H23.7859V8.72743C23.7859 7.68784 22.9432 6.84508 21.9036 6.84508H10.0961C9.0565 6.84508 8.21375 7.68784 8.21375 8.72743V17.3691H10.0961Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0961 20.1067V23.2725L21.9036 23.2725V20.1067H23.7859V23.2725C23.7859 24.3121 22.9432 25.1548 21.9036 25.1548H10.0961C9.05651 25.1548 8.21375 24.3121 8.21375 23.2725V20.1067H10.0961Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.941148 17.7969C1.46095 17.7969 1.88232 18.2183 1.88232 18.7381L1.88232 31.0589C1.88232 31.5787 1.46095 32.0001 0.941149 32.0001C0.421352 32.0001 -2.71733e-05 31.5787 -2.71961e-05 31.0589L-2.77346e-05 18.7381C-2.77573e-05 18.2183 0.421351 17.7969 0.941148 17.7969Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.0588 17.7969C31.5786 17.7969 32 18.2183 32 18.7381L32 31.0589C32 31.5787 31.5786 32.0001 31.0588 32.0001C30.539 32.0001 30.1176 31.5787 30.1176 31.0589L30.1176 18.7381C30.1176 18.2183 30.539 17.7969 31.0588 17.7969Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 18.738C32 19.2578 31.5786 19.6792 31.0588 19.6792L0.941192 19.6792C0.421395 19.6792 1.60933e-05 19.2578 1.60933e-05 18.738C1.60933e-05 18.2182 0.421395 17.7968 0.941192 17.7968L31.0588 17.7968C31.5786 17.7968 32 18.2182 32 18.738Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4655 14.6311C11.4655 15.1509 11.0441 15.5723 10.5243 15.5723H7.78631C7.26651 15.5723 6.84514 15.1509 6.84514 14.6311C6.84514 14.1113 7.26651 13.6899 7.78631 13.6899H10.5243C11.0441 13.6899 11.4655 14.1113 11.4655 14.6311Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1549 14.6311C25.1549 15.1509 24.7335 15.5723 24.2137 15.5723H21.4758C20.956 15.5723 20.5346 15.1509 20.5346 14.6311C20.5346 14.1113 20.956 13.6899 21.4758 13.6899H24.2137C24.7335 13.6899 25.1549 14.1113 25.1549 14.6311Z"
        fill="#BACDE3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 23.2727C16.5198 23.2727 16.9412 23.6941 16.9412 24.2139L16.9412 31.0588C16.9412 31.5786 16.5198 32 16 32C15.4802 32 15.0588 31.5786 15.0588 31.0588L15.0588 24.2139C15.0588 23.6941 15.4802 23.2727 16 23.2727Z"
        fill="#BACDE3"
      />
    </svg>
  )
}

export default HintChairIcon
